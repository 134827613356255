import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async savePerguntaFichaDeSaude({ commit }, data) {
      try {
        return await api.post('/perguntas-ficha-saude', data)
      } catch (error) {
        throw error
      }
    },

    async getPerguntasFichasSaude({ commit }, data) {
      try {
        let url = `/perguntas-ficha-saude`

        if (data && data.status) {
          url += `?status=${data.status}`
        }
        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getPerguntaFichaSaude({ commit }, alunoId) {
      try {
        const response = await api.get(`/perguntas-ficha-saude/${alunoId}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updatePerguntaFichaSaude({ commit }, data) {
      try {
        return await api.put(`/perguntas-ficha-saude/${data.id}`, data.aluno)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}

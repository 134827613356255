import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveSetor({ commit }, setor) {
      try {
        return await api.post('/unidades', setor)
      } catch (error) {
        throw error
      }
    },

    async getSetores({ commit }) {
      try {
        const response = await api.get('/setores')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getSetor({ commit }, setorId) {
      try {
        const response = await api.get(`/setores/${setorId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getSetorByUnidade({ commit }, unidadeId) {
      try {
        const response = await api.get(`/setores/byUnidade/${unidadeId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateSetor({ commit }, setor) {
      try {
        return await api.put(`/unidades/${setor.id}`, setor)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}

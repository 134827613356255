import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveUnidade({ commit }, unidade) {
      try {
        return await api.post('/unidades', unidade)
      } catch (error) {
        throw error
      }
    },

    async getUnidades({ commit }) {
      try {
        const response = await api.get('/unidades')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getUnidade({ commit }, unidadeId) {
      try {
        const response = await api.get(`/unidades/${unidadeId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateUnidade({ commit }, unidade) {
      try {
        return await api.put(`/unidades/${unidade.id}`, unidade)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}

import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveLancamento({ commit }, data) {
      try {
        return await api.post('/lancamentos', data)
      } catch (error) {
        throw error
      }
    },

    async savePreLancamento({ commit }, data) {
      try {
        return await api.post('/pre-lancamento', data)
      } catch (error) {
        throw error
      }
    },

    async savePreLancamentoArray({ commit }, data) {
      try {
        return await api.post('/pre-lancamento-array', data)
      } catch (error) {
        throw error
      }
    },

    async getLancamento({ commit }, lancamentoId) {
      try {
        const response = await api.get(`/lancamentos/${lancamentoId}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getRateioByLancamento({ commit }, lancamentoId) {
      try {
        const response = await api.get(`/lancamentos/${lancamentoId}/rateio`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getLancamentos({ commit }, data) {
      try {
        const response = await api.get(
          `/lancamentos/${data.tipo}/${data.data_inicial}/${data.data_final}/${data.contas}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getPreLancamento({ commit }, data) {
      try {
        let url = `/pre-lancamento`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getParcelasByContratoPreLancamento({ commit }, data) {
      try {
        const response = await api.get(
          `/pre-lancamento/${data.contratoId}/parcelas`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getContasAdicionaisByContratoPreLancamento({ commit }, data) {
      try {
        const response = await api.get(
          `/pre-lancamento/${data.contratoId}/contas-adicionais`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateLancamento({ commit }, data) {
      try {
        return await api.put(`/lancamentos/${data.id}`, data)
      } catch (error) {
        throw error
      }
    },

    async baixarPagamento({ commit }, data) {
      try {
        return await api.put(`/lancamentos/${data.id}/baixarLancamento`, data)
      } catch (error) {
        throw error
      }
    },

    async baixarPagamentosArray({ commit }, data) {
      try {
        console.log(data)
        return await api.put(`/baixar-lancamentos-array`, data)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}

import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveAluno({ commit }, data) {
      try {
        console.log(data)
        return await api.post('/alunos', data)
      } catch (error) {
        throw error
      }
    },

    async getAlunos({ commit }, data) {
      try {
        let url = `/alunos`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getAluno({ commit }, alunoId) {
      try {
        const response = await api.get(`/alunos/${alunoId}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getFichaSaudeAluno({ commit }, alunoId) {
      try {
        const response = await api.get(`/alunos/${alunoId}/ficha-saude`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateAluno({ commit }, data) {
      try {
        return await api.put(`/alunos/${data.id}`, data.aluno)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
